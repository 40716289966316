import './App.css';
import { useState, useEffect } from 'react';

const apiRoot = "https://prefect-backend.ycat36.workers.dev/"
// devAPI
// const apiRoot = "http://localhost:8787/"

function getKey(){
  // get key used to open this page
  var key = window.location.hash
  // remove the # from the key
  key = key.substring(1)
  // if key is "" or less than 5 characters
  if(key === "" || key.length < 5){
    // get it from local storage
    key = localStorage.getItem("key")
  }
  // if key is still ""
  if(key.length < 5){
    key = prompt("Please enter the key")
  }
  // save the key to local storage
  localStorage.setItem("key", key)
  return key
}

function MarkOverrider(props){
  const data = props.data;
  const setData = props.setData;
  // create state for form data
  const [formData, setFormData] = useState({
    "personid": "",
    "date": "",
    "type": "",
    "mark": "",
    "reason": ""
  });
  // formHandler
  const formHandler = (e) => {
    e.preventDefault();
    // get the data from the form
    const personid = formData.personid;
    const date = formData.date;
    const type = formData.type;
    const mark = formData.mark;
    /* calculate timestamp 
    if type = "Meeting" get the timestamp for 9am on the date, otherwise use timestamp for 12am on the date*/
    var timestamp = 0;
    if(type === "Meeting"){
      timestamp = new Date(date).getTime() / 1000 + 32400;
    }
    else{
      timestamp = new Date(date).getTime() / 1000 + 43200;
    }
    var dataToSend = []
    dataToSend.push({
      "id": personid,
      "timestamp": timestamp,
      "markerName": "Manual Override",
      "markerID": "W7",
      "temp": false,
      "uniqueMarkerID": "Manual Override",
      "type": type,
      "mark": mark,
      "location": "{'error': 'manualoverride'}"
    })
    const key = getKey();
    fetch(apiRoot + "?command=returnAttendance&timestampoverride=" + timestamp + "&key=" + key, {
      method: "POST",
      body: JSON.stringify(dataToSend),
    })
    // destroy the data for this week
    var newData = data
    delete newData.attendance[timestamp - (timestamp % 604800)]
    setData(newData)
  }
    
  // create a form to override marks
  return (
    <form onSubmit={formHandler}>
      <input type="text" placeholder="Person ID" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "personid": e.target.value
      }))}></input>
      <input type="date" placeholder="Date" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "date": e.target.value
      }))}></input>
      <input type="text" placeholder="Type" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "type": e.target.value
      }))}></input>
      <input type="text" placeholder="Mark" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "mark": e.target.value
      }))}></input>
      <input type="text" placeholder="Reason (note: only relevant for excuses, DO NOT USE)" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "reason": e.target.value
      }))}></input>
      <input type="submit" value="Submit"></input>
    </form>
  )
}

function SideBar({ currentActiveTab, setcurrentActiveTab }) {
  // create keyboard shortcuts for each of these Ctrl + A, Ctrl + U, Ctrl + G, Ctrl + D etc
  useEffect(() => {
    window.addEventListener("keydown", function (e) {
      if (e.ctrlKey && e.key === "u") {
        setcurrentActiveTab("users")
        e.preventDefault()
      }
      if (e.ctrlKey && e.key === "g") {
        setcurrentActiveTab("groups")
        e.preventDefault()
      }
      if (e.ctrlKey && e.key === "d") {
        setcurrentActiveTab("dutyAttendance")
        e.preventDefault()
      }
      if (e.ctrlKey && e.key === "m") {
        setcurrentActiveTab("meetingAttendance")
        e.preventDefault()
      }
      if (e.ctrlKey && e.key === "e") {
        setcurrentActiveTab("emails")
        e.preventDefault()
      }
      if (e.ctrlKey && e.key === "s") {
        setcurrentActiveTab("settings")
        e.preventDefault()
      }
      if (e.ctrlKey && e.key === "x") {
        setcurrentActiveTab("excuses")
        e.preventDefault()
      }
      if (e.ctrlKey && e.key === "h") {
        setcurrentActiveTab("help")
        e.preventDefault()
      }
      if (e.ctrlKey && e.key === "y") {
        setcurrentActiveTab("activateUserSession")
        e.preventDefault()
      }
    })
  }, [setcurrentActiveTab])
  // create a sidebar with multiple options and make it 400px wide
  // on click of any option, change the currentActiveTab state
  return (
    <div id="sidebar">
      <div className={`sidebar-option ${currentActiveTab === "dashboard" ? "active" : ""}`} onClick={() => setcurrentActiveTab("dashboard")}>Dashboard</div>
      <div className={`sidebar-option ${currentActiveTab === "users" ? "active" : ""}`} onClick={() => setcurrentActiveTab("users")}>Users</div>
      <div className={`sidebar-option ${currentActiveTab === "groups" ? "active" : ""}`} onClick={() => setcurrentActiveTab("groups")}>Groups</div>
      <div className={`sidebar-option ${currentActiveTab === "dutyAttendance" ? "active" : ""}`} onClick={() => setcurrentActiveTab("dutyAttendance")}>Duty Attendance</div>
      <div className={`sidebar-option ${currentActiveTab === "meetingAttendance" ? "active" : ""}`} onClick={() => setcurrentActiveTab("meetingAttendance")}>Meeting Attendance</div>
      <div className={`sidebar-option ${currentActiveTab === "emails" ? "active" : ""}`} onClick={() => setcurrentActiveTab("emails")}>Emails</div>
      <div className={`sidebar-option ${currentActiveTab === "settings" ? "active" : ""}`} onClick={() => setcurrentActiveTab("settings")}>Settings</div>
      <div className={`sidebar-option ${currentActiveTab === "excuses" ? "active" : ""}`} onClick={() => setcurrentActiveTab("excuses")}>Excuses</div>
      <div className={`sidebar-option ${currentActiveTab === "chat" ? "active" : ""}`} onClick={() => setcurrentActiveTab("chat")}>Chat</div>
      <div className={`sidebar-option ${currentActiveTab === "help" ? "active" : ""}`} onClick={() => setcurrentActiveTab("help")}>Help</div>
      <div className={`sidebar-option ${currentActiveTab === "activateUserSession" ? "active" : ""}`} onClick={() => setcurrentActiveTab("activateUserSession")}>Activate User Session</div>
    </div>
  )
}

function Dashboard() {
  return (
    <div>
      <h1>Dashboard</h1>
    </div>
  )
}

function UserCreator(props){
  // create state for form data
  const [formData, setFormData] = useState({
    "sysFullName": "",
    "preferredFullName": "",
    "groups": "",
    "year": "",
    "tutor_group": "",
    "email": "",
    "notes": "",
    "privNotes": ""
  });
  // formHandler
  const formHandler = (e) => {
    e.preventDefault();
    // get the data from the form
    const sysFullName = formData.sysFullName;
    const preferredFullName = formData.preferredFullName;
    const groups = formData.groups;
    const year = formData.year;
    const tutor_group = formData.tutor_group;
    const email = formData.email;
    const notes = formData.notes;
    const privNotes = formData.privNotes;
    // get the id of the group
    const groupid = props.data.getData.groups.find(group => group.name === groups).id
    // generate a UUID4 for the person
    const id = crypto.randomUUID() 
    // create the data to send
    const dataToSend = {
      "preferredFullName": preferredFullName,
      "sysFullName": sysFullName,
      "id": id,
      "groups": [
        groupid
      ],
      "tutor_group": tutor_group,
      "email": email,
      "notes": notes,
      "privNotes": privNotes,
      "year": year
    }
    // push to api
    const key = getKey();
    fetch(apiRoot + "?command=addPerson&key=" + key, {
      method: "POST",
      body: JSON.stringify(dataToSend),
    })
  }
  return(
    <form onSubmit={formHandler}>
      <input type="text" placeholder="System Name" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "sysFullName": e.target.value
      }))}></input>
      <input type="text" placeholder="Preferred Name" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "preferredFullName": e.target.value
      }))}></input>
      <input type="text" placeholder="Group" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "groups": e.target.value
      }))}></input>
      <input type="text" placeholder="Year" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "year": e.target.value
      }))}></input>
      <input type="text" placeholder="Tutor Group" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "tutor_group": e.target.value
      }))}></input>
      <input type="text" placeholder="Email" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "email": e.target.value
      }))}></input>
      <input type="text" placeholder="Notes" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "notes": e.target.value
      }))}></input>
      <input type="text" placeholder="Private Notes" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "privNotes": e.target.value
      }))}></input>
      <input type="submit" value="Submit"></input>
    </form>
  )
}

function UserEditor(props){
  /* example person
      {
      "preferredFullName": "Isaac Hatton",
      "id": "f5b0531c-dffd-4a6e-96e3-ecdd6110deb6",
      "groups": [
        "db86a2ee2653414d9df9eaa447281a47"
      ],
      "tutor_group": "11C1",
      "email": "hatti014@office.holytrinitycrawley.org.uk",
      "notes": "",
      "privNotes": "",
      "sysFullName": "Isaac Hatton",
      "year": "11"
    },
  */
  // find the person in the data
  const person = props.data.getData.people.find(person => person.id === props.person)
  // find the groupname for this person
  var groupname = ""
  if(person.groups.length > 0){
    groupname = props.data.getData.groups.find(group => group.id === person.groups[0]).name
  }
  // submit handler
  const submitHandler = (e) => {
    e.preventDefault();
    // get the id of the person from the Submit button id
    const id = e.target.id.substring(6)
    // find the form
    const form = document.getElementById("Form " + id)
    console.log(props.data.getData.groups)
    const groupid = props.data.getData.groups.find(group => group.name === form[2].value).id
    // get the data from the form
    const newData = {
      "preferredFullName": form[1].value,
      "sysFullName": form[0].value,
      "id": props.person,
      "groups": [
        groupid
      ],
      "tutor_group": form[4].value,
      "email": form[5].value,
      "notes": form[6].value,
      "privNotes": form[7].value,
      "year": form[3].value
    }
    // push to api
    const key = getKey();
    fetch(apiRoot + "?command=updatePerson&key=" + key, {
      method: "POST",
      body: JSON.stringify(newData),
    })
    /* at this point state code is broken, so just reload the page
    // update state
    var data = props.data
    // find the person in the data
    const index = data.getData.people.findIndex(person => person.id === props.person)
    // replace the person with the new data
    data.getData.people[index] = newData
    // update the state
    props.setData(data)
    */
    window.location.reload()
  }
  // return a form with the person's name, groupname and other details
  return(
    <div className="container">
      <form id={"Form " + person.id}>
        <input type="text" placeholder="System Name" defaultValue={person.sysFullName}></input>
        <input type="text" placeholder="Preferred Name" defaultValue={person.preferredFullName}></input>
        <input type="text" placeholder="Group" defaultValue={groupname}></input>
        <input type="text" placeholder="Year" defaultValue={person.year}></input>
        <input type="text" placeholder="Tutor Group" defaultValue={person.tutor_group}></input>
        <input type="text" placeholder="Email" defaultValue={person.email}></input>
        <input type="text" placeholder="Notes" defaultValue={person.notes}></input>
        <input type="text" placeholder="Private Notes" defaultValue={person.privNotes}></input>
        <div type="submit" value="Update" id={"Submit" + person.id}onClick={submitHandler}>📝Save Edit to server</div>
      </form>
      <DeletePersonButton person={person} data={props.data} setData={props.setData}></DeletePersonButton>
    </div>
  )
}

function DeletePersonButton(props){
  const deletePerson = () => {
    // delete the person from the API
    const key = getKey();
    fetch(apiRoot + "?command=deletePerson&id=" + props.person.id + "&key=" + key, {
      method: "GET",
    })
    alert("Deleted " + props.person.sysFullName + " (DO NOT double click)")
    /* at this point state code is broken, so just reload the page
    // delete the person from the state
    var data = props.data
    // find the person in the data
    const index = data.getData.people.findIndex(person => person.id === props.person.id)
    // delete the person from the state
    data.getData.people.splice(index, 1)
    // update the state
    props.setData(data) */
    window.location.reload()
  }
  return(
    <div onClick={deletePerson}>🗑️Delete {props.person.sysFullName}</div>
  )
  
}
function Users(props) {
  const people = props.data.getData.people
  return (
    <div>
      <h1>Users</h1>
      <UserCreator data={props.data} setData={props.setData}></UserCreator>
      {people.map((person, index) => <UserEditor key={index} person={person.id} data={props.data} setData={props.setData}></UserEditor>)}
    </div>
  )
}

function EditGroup(props){
  const [groupName, setGroupName] = useState(props.group.name);
  const [dutyDay, setDutyDay] = useState(props.group.duties[0].day);
  const [dutyTime, setDutyTime] = useState(props.group.duties[0].time);
  const [dutyLocation, setDutyLocation] = useState(props.group.duties[0].location);
  const [dutyWeek, setDutyWeek] = useState(props.group.duties[0].week);

  var groupMembers = []
  const people = props.data.getData.people
  // loop through each person in props.data.getData.people
  for(let i = 0; i < people.length; i++ ){
    if(people[i]["groups"][0] === props.group.id){
      groupMembers.push(people[i])
    }
  }
  // submit handler
  const submitHandler = (e) => {
    e.preventDefault();
    // get the data from the form
    const newData = {
      "name": groupName,
      "id": props.group.id,
      "duties": [
        {
          "day": dutyDay,
          "time": dutyTime,
          "location": dutyLocation,
          "week": dutyWeek
        }
      ]
    }
    // push to api
    const key = getKey();
    fetch(apiRoot + "?command=updateGroup&key=" + key, {
      method: "POST",
      body: JSON.stringify(newData),
    })
    /* at this point state code is broken, so just reload the page
    // update state
    var data = props.data
    // find the person in the data
    const index = data.getData.groups.findIndex(group => group.id === props.group.id)
    // replace the person with the new data
    data.getData.groups[index] = newData
    // update the state
    props.setData(data) */
    window.location.reload()
  }
  return (
    <div className="container">
      <form>
        <input type="text" placeholder="Group Name" value={groupName} onChange={(e) => setGroupName(e.target.value)}></input>
        <input type="text" placeholder="Duty day" value={dutyDay} onChange={(e) => setDutyDay(e.target.value)}></input>
        <input type="text" placeholder="Duty time" value={dutyTime} onChange={(e) => setDutyTime(e.target.value)}></input>
        <input type="text" placeholder="Duty location" value={dutyLocation} onChange={(e) => setDutyLocation(e.target.value)}></input>
        <input type="text" placeholder="Duty week" value={dutyWeek} onChange={(e) => setDutyWeek(e.target.value)}></input>
        <div type="submit" value="Submit" onClick={submitHandler}>📝Save edits to server</div>
      </form>
      <h3>Members of this group</h3>
      {groupMembers.map(member => <p>{member.sysFullName}</p>)}
      {/* if there are no members */}
      {groupMembers.length === 0? <DeleteGroupButton group={props.group} data={props.data} setData={props.setData}></DeleteGroupButton> : ""}
    </div>
  )
}

function CreateGroup(props){
  // create state for formdata
  const [formData, setFormData] = useState({
    "name": "",
    "dutyDay": "",
    "dutyTime": "",
    "dutyLocation": "",
    "dutyWeek": ""
  });
  // formHandler
  const formHandler = (e) => {
    e.preventDefault();
    // get the data from the form
    const name = formData.name;
    const dutyDay = formData.dutyDay;
    const dutyTime = formData.dutyTime;
    const dutyLocation = formData.dutyLocation;
    const dutyWeek = formData.dutyWeek;
    // generate a UUID4 for the group
    const id = crypto.randomUUID() 
    // create the data to send
    const dataToSend = {
      "name": name,
      "id": id,
      "duties": [
        {
          "day": dutyDay,
          "time": dutyTime,
          "location": dutyLocation,
          "week": dutyWeek
        }
      ]
    }
    // push to api
    const key = getKey();
    fetch(apiRoot + "?command=createGroup&key=" + key, {
      method: "POST",
      body: JSON.stringify(dataToSend),
    })
  }
  return(
    <form onSubmit={formHandler}>
      <input type="text" placeholder="Group Name" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "name": e.target.value
      }))}></input>
      <input type="text" placeholder="Duty day" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "dutyDay": e.target.value
      }))}></input>
      <input type="text" placeholder="Duty time" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "dutyTime": e.target.value
      }))}></input>
      <input type="text" placeholder="Duty location" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "dutyLocation": e.target.value
      }))}></input>
      <input type="text" placeholder="Duty week" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "dutyWeek": e.target.value
      }))}></input>
      <input type="submit" value="Submit"></input>
    </form>
  )
}

function DeleteGroupButton(props){
  const deleteGroupHandler = function (){
    // delete the person from the API
    const key = getKey();
    fetch(apiRoot + "?command=deleteGroup&id=" + props.group.id + "&key=" + key, {
      method: "GET",
    })
    /* at this point state code is broken, so just reload the page
    // delete the person from the state
    var data = props.data
    // find the person in the data
    const index = data.getData.groups.findIndex(group => group.id === props.group.id)
    // delete the person from the state
    data.getData.groups.splice(index, 1)
    // update the state
    props.setData(data) */
    window.location.reload()
  }
  return (
    <div onClick={deleteGroupHandler}>🗑️Delete this group (it's empty)</div>
  )
}

function Groups(props) {
  const groups = props.data.getData.groups
  return (
    <div>
      <h1>Groups</h1>
      <CreateGroup data={props.data} setData={props.setData}></CreateGroup>
      {groups.map(group => <EditGroup key={group.id} group={group} data={props.data} setData={props.setData}></EditGroup>)}
    </div>
  )
}

function AttendanceCell(props){
  const type = props.type
  const user = props.user;
  const attendanceData = props.attendanceData;
  const weeks = props.weeks;
  const weeknum = props.weeknum;
  const week = weeks[weeknum];
  const userID = user.id;
  if(attendanceData !== undefined){
    const weekData = attendanceData[week];
    if(weekData !== undefined){
      const weekAttendance = weekData.attendance;
      const weekExcuses = weekData.excuses;
      // group records by timestamp
      var { importanceRating, importantRecord } = getImportantRecordFromWeek(weekAttendance, type, userID, weekExcuses); 
      // if important record is not empty
      if(importanceRating > -10000000000000000000000000000000000000000000000000000000000000000){
        return(
          <p className={importantRecord.mark}>{importantRecord.mark}</p>
        )
      }else{
        return(
          <p>?</p>
        )
      }
    }else{
      // loading emoji
      return(
        <p>🔃</p>        
      )
    }
  }else{
    return(
      <p>🔃</p>
    )
  }
}

function getImportantRecordFromWeek(weekAttendance, type, userID, excuses = []) {
  var importantRecord = [];
  var importanceRating = -10000000000000000000000000000000000000000000000000000000000000000;
  // loop through weekAttendance and ignore all that don't match the user
  for (var i = 0; i < weekAttendance.length; i++) {
    // convert weekAttendance[i].timestamp to a date
    var date = new Date(weekAttendance[i].timestamp * 1000);
    var valid = checkTimeValidityForType(type, date);
    if (weekAttendance[i].personid === userID && valid) {
      // check if the record is a meeting
      var currentRating = 0;
      // if the record is a temp record remove 1000 from the rating
      if (weekAttendance[i].temp === 1) {
        currentRating -= 1000;
      }
      // if the record has a markerID of W7 or AD add 500 to the rating
      if (weekAttendance[i].markerID === "W7" || weekAttendance[i].markerID === "AD") {
        currentRating += 500;
      }
      // then add the timestamp divided by 1000000000000000000 to the rating
      currentRating += weekAttendance[i].timestamp / 1000000000;
      // if the current rating is higher than the importance rating set the importance rating to the current rating and set the important record to the current record
      if (currentRating > importanceRating) {
        importanceRating = currentRating;
        importantRecord = weekAttendance[i];
      }
    }
  }
  // if importantRecord.mark is not present
  if (importantRecord.mark !== "present") {
    // loop through excuses and find the excuse for this record
    for (var j = 0; j < excuses.length; j++) {
      if(excuses[j].personid === userID){
        const date = new Date(excuses[j].eventTimestamp * 1000);
        // check if record time is right
        const valid = checkTimeValidityForType(type, date);
          console.log(excuses[j].eventTimestamp)
          console.log(date)
          console.log(valid)
        if(valid){
          // set importanceRating to a positive number to avoid problems
          importanceRating = 100000
          // override the mark
          importantRecord.mark = excuses[j].approved
          importantRecord = {
            "mark": excuses[j].approved,
            "timestamp": excuses[j].eventTimestamp
          }
        }
      }
    }
  }
  return { importanceRating, importantRecord };
}

function checkTimeValidityForType(type, date) {
  var valid = false;
  if (type === "Meeting") {
    // check if the timestamp is before 10:30 UTC
    if (date.getHours() < 10 || (date.getHours() === 10 && date.getMinutes() < 30)) {
      valid = true;
    }
  }
  // check if the record is a duty
  if (type === "Duties") {
    // check if the timestamp is after 10:30 UTC
    if (date.getHours() > 10 || (date.getHours() === 10 && date.getMinutes() > 30)) {
      valid = true;
    }
  }
  return valid;
}

function AttendanceRow(props){
  // create a row for a user
  return (
    <tr>
      <td>{props.user.sysFullName + " " + props.user.tutor_group}</td>
      {props.weeks.map((week, index) => <td skey={index}><AttendanceCell user={props.user} weeks={props.weeks} weeknum={index} attendanceData={props.data.attendance} type={props.type}></AttendanceCell></td>)}
    </tr>)
}

function prettyDate(unix){
  // convert unix timestamp to date
  const date = new Date(unix * 1000);
  // give in dd/mm/yy format
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

function AttendanceTable(props){
  // get the monday that starts this week
  const weeks = generateWeeks();
  // for each week
  fetchAttendanceData(weeks, props.data, props.setData);
  // create a way to filter the people
  var people = props.data.getData.people
  // if the type is meetings, filter out all people who are not in year 10 or 11
  if(props.type === "Meeting"){
    people = people.filter(person => person.year === "10" || person.year === "11")
  }
  // get props.filter
  const filter = props.filter.toLowerCase().replace(/[^a-z0-9]/g, ''); // Convert filter to lowercase and remove non-alphanumeric characters
  // for each person
  if(filter !== ""){
    /* this is a really hacky implementation, basically get each tutor group and stringify it and if it contains that string,
    all of the people in that tutor group will be shown
    repeat same proceedure for groups
    then also check if the person's stringified data contains the filter */
    people = people.filter(person => JSON.stringify(person).toLowerCase().replace(/[^a-z0-9]/g, '').includes(filter) || JSON.stringify(props.data.getData.tutordata.find(tutor => tutor.tutor === person.tutor_group)).toLowerCase().replace(/[^a-z0-9]/g, '').includes(filter) || JSON.stringify(props.data.getData.groups.find(group => group.id === person.groups[0])).toLowerCase().replace(/[^a-z0-9]/g, '').includes(filter))
  }
  // check if there is actually 7 pieces of attendanceData in props.data.attendance
  // prevents flashyness
  if(props.data.attendance !== undefined){
    if(Object.keys(props.data.attendance).length !== 7){
      return(
        <p>🔃Some data arrived, waiting for the rest...</p>
      )
    }
  }else{
    return(
      <p>🔃No attendance data loaded yet...</p>
    )
  }
  // create a table with 8 columns (first is name, rest are weeks)
  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          {weeks.map((week, index) => <th key={index}>{prettyDate(week)}</th>)}
        </tr>
      </thead>
      <tbody>
        {people?.map((user, index) => <AttendanceRow key={index} user={user} weeks={weeks} data={props.data} type={props.type}></AttendanceRow>)}
      </tbody>
    </table>
  )
}

function fetchAttendanceData(weeks, data, setData) {
  for (var i = 0; i < weeks.length; i++) {
    const unix = weeks[i];
    const start = unix;
    const end = unix + 604800;
    // fetch data for each week from the API and then save it
    if (data.attendance === undefined || data.attendance[weeks[i]] === undefined) {
      const fetchData = async () => {
        const dataFromAPI = await fetch(apiRoot + `?command=getMarksheet&key=${getKey()}&start=${start}&end=${end}`).then(res => res.json());
        var newData = { ...data };
        // if attendance is undefined, create it
        if (newData["attendance"] === undefined) {
          newData["attendance"] = {};
        }
        newData["attendance"][unix] = dataFromAPI;
        setData(newData);
      };
      fetchData();
    }
  }
}

function generateWeeks() {
  const today = new Date();
  const monday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
  // get unix timestamp (seconds) of 12:00am on the monday
  const mondayUnix = monday.getTime() / 1000;
  // create an array of the 7 previous weeks, starting with this week
  const weeks = [];
  for (var j = 0; j < 7; j++) {
    weeks.push(mondayUnix - (j * 604800));
  }
  return weeks;
}

function Attendance(props) {
  // create state for the filter
  const [filter, setFilter] = useState("");
  return (
    <div>
      <h1>Attendance - {props.type}</h1>
      <input type="text" placeholder="Filter" onChange={(e) => setFilter(e.target.value)}></input>
      <MarkOverrider data={props.data} setData={props.setData}></MarkOverrider>
      <AttendanceTable data={props.data} setData={props.setData} type={props.type} filter={filter}></AttendanceTable>
    </div>
  )
}


function GenerateEmailButton(props){
  // create a mailto link
  const mailto = `mailto:${props.notification.to}?subject=${props.notification.subject}&body=${props.notification.body}`
  // return a button that opens the mailto link
  return(
    <>
      <a href={mailto}>Send email {props.notification.subject}</a>
      <br></br>
    </>
  )
}

function Emails(props) {
  var notifications = []
  var errors = []
  try{
    // fetch attendance data from the last 3 weeks and wait for it to be fetched
    const weeks = generateWeeks()
    const weeksToFetch = [weeks[0], weeks[1], weeks[2]]
    fetchAttendanceData(weeksToFetch, props.data, props.setData)
    for(let i = 0; i < props.data.getData.people.length; i++){
        // get the initialism for this person (school policy, don't put student names in email subject)
        const initials = props.data.getData.people[i].sysFullName.split(" ").map(name => name[0]).join("")
        // get mark for most recent week
        const person = props.data.getData.people[i]
        const unixForWeek = generateWeeks()[0]
        const attendance = props.data.attendance[unixForWeek].attendance
        const excuses = props.data.attendance[unixForWeek].excuses
        // get most important record
        try{
          var importantRecord = getImportantRecordFromWeek(attendance, "Duties", person.id, excuses)
          console.log(importantRecord.importantRecord.mark)
          if(importantRecord.importantRecord.mark === "absent"){
            // get the person's tutor
            const tutors = props.data.getData.tutordata.find(tutor => tutor.tutor === person.tutor_group).tutors
            // for each tutor in the tutor group
            for(let j = 0; j < tutors.length; j++){
              // get the tutor's email
              const tutor = tutors[j]
              const tutorEmail = tutors[j].email
              // push to notificaitons
              notifications.push({
                "subject": initials + " Student Leadership - Absent from duty",
                "body": `Dear ${tutor["teacher-name"]},\n\n${person.sysFullName} was absent from duty this week. Please remind him/her to turn up. \n\nKind regards,\n\n Isaac Hatton`,
                "to": tutorEmail
              })
              console.log(notifications)
            }
          }else if(importantRecord.importantRecord.mark === undefined){
            // if student is in y10 or y11
            if(person.year === "10" || person.year === "11"){
              // get the person's tutor
              const tutors = props.data.getData.tutordata.find(tutor => tutor.tutor === person.tutor_group).tutors
              // for each tutor in the tutor group
              for(let j = 0; j < tutors.length; j++){
                // get the tutor's email
                const tutor = tutors[j]
                const tutorEmail = tutors[j].email
                // push to notificaitons
                notifications.push({
                  "subject": initials + " Student Leadership - Didn't sign in",
                  "body": `Dear ${tutor["teacher-name"]},\n\n${person.sysFullName} did not sign into duty this week. Please remind him/her to turn up. \n\nKind regards,\n\n Isaac Hatton`,
                  "to": tutorEmail
                })
                console.log(notifications)
              }
            }else{
              // nag their y11 and y10 student leaders

            }
          }
        }catch(e){
          errors.push(e)
          console.warn(e)
        }
    }
  }catch(e){
    errors.push(e)
    console.warn(e)
  }
  console.log(props)
  return (
    <div>
      <h1>Emails</h1>
      {errors.length > 0 ? errors.map((error, index) => <p className="error" key={index}>ERROR EMAILS MAY BE INCORRECT: {JSON.stringify(error)}</p>) : ""}

      <h2>Notifications</h2>
      {notifications.map((notification, index) => <GenerateEmailButton key={index} notification={notification}></GenerateEmailButton>)}
    </div>
  )
}

function Settings() {
  return (
    <div>
      <h1>Settings</h1>
    </div>
  )
}

function Excuses(props) {
  // create state for form data
  const [formData, setFormData] = useState({
    "date": "",
    "type": "Duties",
    "people": []
  });
  return (
    <div>
      <h1>Excuses</h1>
      <h2>Excuser</h2>
      {/* create a form to submit the data */}
      <form onSubmit={(e) => { 
        e.preventDefault();
        // get the data from the form
        const dateFromForm = formData.date;
        // convert date to a date object
        const date = new Date(dateFromForm)
        const type = formData.type;
        console.log(type)
        const people = formData.people;
        console.log(date)
        // if type is meeting, add 8hrs 30mins to make it be 8:30am 
        if(type === "Meeting"){
          date.setHours(date.getHours() + 8)
          console.log("meeting")
          console.log(date)
        }
        // if type is duties, add 12hrs to make it be 12:00pm
        if(type === "Duties"){
          date.setHours(date.getHours() + 12)
          console.log("duties")
          console.log(date)
        }
        // generate a UUID4 for the excuse
        const id = crypto.randomUUID() 
        // create the data to send
        const dataToSend = {
          "id": id,
          "eventTimestamp": date.getTime() / 1000,
          "type": type,
          "people": people,
          "approved": "approved"
        }
        // push to api
        const key = getKey();
        fetch(apiRoot + "?command=manualExcuseSubmit&key=" + key, {
          method: "POST",
          body: JSON.stringify(dataToSend),
        })
      }}>
        <input type="date" placeholder="Date" onChange={(e) => setFormData(prevData => ({
        ...prevData,
        "date": e.target.value
      }))}></input>
      {/* for type create a dropdown of Meeting and Duties*/}
      <select onChange={(e) => {
        setFormData(prevData => ({
          ...prevData,
          "type": e.target.value
        }))
      }} type="type">
        <option value="Duties">Duties</option>
        <option value="Meeting">Meeting</option>
      </select>
      {/* for each person in data create a checkbox */}
      {props.data.getData.people.map((person, index) => <div key={index}><input type="checkbox" onChange={(e) => {
        // if the checkbox is checked, add the person to the form data
        if(e.target.checked){
          setFormData(prevData => ({
            ...prevData,
            "people": [...prevData.people, person.id]
          }))
        }else{
          // if the checkbox is unchecked, remove the person from the form data
          setFormData(prevData => ({
            ...prevData,
            "people": prevData.people.filter(personid => personid !== person.id)
          }))
        }
      }}></input>{person.sysFullName}</div>)}
        <input type="submit" value="Submit"></input>
      </form>
    </div>
  )
}

function Chat() {
  return (
    <div>
      <h1>Chat</h1>
    </div>
  )
}

function Help() {
  return (
    <div>
      <h1>Help</h1>
    </div>
  )
}

function ActivateUserSession() {
  return (
    <div>
      <h1>Activate User Session</h1>
    </div>
  )
}

function App() {
  // create state to store data in
  const [data, setData] = useState({});
  // create a useEffect to get data
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(apiRoot + `?command=getData&key=${getKey()}`).then(res => res.json());
      setData(prevData => ({
        ...prevData,
        "getData": data
      }));
    };
    fetchData();
  }, []);

  // current active tab
  const [currentActiveTab, setcurrentActiveTab] = useState("dashboard");

  // Loading message
  if (Object.keys(data).length === 0) {
    return <div>Loading...</div>;
  }
  var bodytag = ""
  // if the apiRoot is a workers.dev
  if(apiRoot.includes("workers.dev")){
    // set the body tag to be the apiRoot
    bodytag = "prod"    
  }else{
    bodytag = "dev"
  }

  return (
    <>
      <SideBar currentActiveTab={currentActiveTab} setcurrentActiveTab={setcurrentActiveTab}></SideBar>
      <div id="App" className={bodytag}>
        {currentActiveTab === "dashboard"? <Dashboard></Dashboard> : ""}
        {currentActiveTab === "users"? <Users data={data} setData={setData}></Users> : ""}
        {currentActiveTab === "groups"? <Groups data={data} setData={setData}></Groups> : ""}
        {currentActiveTab === "dutyAttendance"? <Attendance data={data} setData={setData} type="Duties"></Attendance> : ""}
        {currentActiveTab === "meetingAttendance"? <Attendance data={data} setData={setData} type="Meeting"></Attendance> : ""}
        {currentActiveTab === "emails"? <Emails data={data} setData={setData}></Emails> : ""}
        {currentActiveTab === "settings"? <Settings></Settings> : ""}
        {currentActiveTab === "excuses"? <Excuses data={data} setData={setData}></Excuses> : ""}
        {currentActiveTab === "chat"? <Chat></Chat> : ""}
        {currentActiveTab === "help" ? <Help></Help> : ""}
        {currentActiveTab === "activateUserSession" ? <ActivateUserSession></ActivateUserSession> : ""}
      </div>
    </>
  );
}

export default App;
